import { getEnvVar } from 'util/env';

export type APIVersion = 'v1' | 'v2';

const API_HOST = getEnvVar('API_BASE_URL');

export function getAPIUrl({
  path,
  version,
}: {
  path: string;
  version: APIVersion;
}) {
  return `${API_HOST}/api/${version === 'v1' ? `${version}/admin` : version}${path}`;
}
